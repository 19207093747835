<template>
    <div class="m-card">
        <!-- Desktop container -->
        <hide-at breakpoint="mediumAndBelow">
            <b-card no-body bg-variant="white" border-variant="dark" header-border-variant="dark">
                <template #header>
                    <router-link :to="{name: 'material-display', params: {id: mid}}">
                        <h4 class="mb-0">
                            {{ title }} <span :class="statusVariant">{{ status }}</span>
                        </h4>
                    </router-link>
                </template>

                <b-card-body>
                    <b-container class="mcontainer">
                        <b-row no-gutters class="mrow">
                            <a v-if="pdfUrl" :href="pdfUrl" download class="download-btn" @click="report(title, 'pdf')">
                                <b-btn variant="success">Скачать pdf</b-btn>
                            </a>
                            <a v-if="zipUrl" :href="zipUrl" download class="download-btn" @click="report(title, 'zip')">
                                <b-btn variant="success">Скачать zip</b-btn>
                            </a>
                            <a v-if="sibUrl" :href="sibUrl" download class="download-btn" @click="report(title, 'sib')">
                                <b-btn variant="success">Скачать sib</b-btn>
                            </a>
                            <a v-if="mp3Url" :href="mp3Url" download class="download-btn" @click="report(title, 'mp3')">
                                <b-btn variant="success">Скачать mp3</b-btn>
                            </a>
                            <b-btn v-if="mp3Url" variant="info" @click.prevent="playSound(title, mp3Url)">
                                Играть
                            </b-btn>
                            <b-btn v-if="mp3Url" variant="warning text-dark" @click.prevent="pauseSound()">
                                Пауза
                            </b-btn>
                        </b-row>
                    </b-container>
                </b-card-body>
            </b-card>
        </hide-at>
        <!-- Desktop container -->

        <!-- Mobile container -->
        <show-at breakpoint="mediumAndBelow">
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button v-b-toggle="mid" block href="#" variant="info">
                        {{ title }} <span :class="statusVariant">{{ status }}</span>
                    </b-button>
                </b-card-header>
                <b-collapse :id="mid" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <a v-if="pdfUrl" :href="pdfUrl" class="mobile-download-btn" download @click="report(title, 'pdf')">
                            <b-btn block variant="success">Скачать pdf</b-btn>
                        </a>
                        <a v-if="zipUrl" :href="zipUrl" class="mobile-download-btn" download @click="report(title, 'zip')">
                            <b-btn block variant="success">Скачать zip</b-btn>
                        </a>
                        <a v-if="sibUrl" :href="sibUrl" class="mobile-download-btn" download @click="report(title, 'sib')">
                            <b-btn block variant="success">Скачать sib</b-btn>
                        </a>
                        <a v-if="mp3Url" :href="mp3Url" class="mobile-download-btn" download @click="report(title, 'mp3')">
                            <b-btn block variant="success">Скачать mp3</b-btn>
                        </a>
                        <b-row>
                            <b-col>
                                <b-btn v-if="mp3Url" block variant="info" @click.prevent="playSound(title, mp3Url)">
                                    Играть
                                </b-btn>
                            </b-col>
                            <b-col>
                                <b-btn v-if="mp3Url" block variant="warning" @click.prevent="pauseSound()">
                                    Пауза
                                </b-btn>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </show-at>
        <!-- Mobile container -->
    </div>
</template>

<script>
import { showAt, hideAt } from 'vue-breakpoints';

export default {
    name: "MaterialCard",
    components: {
        hideAt,
        showAt
    },
    props: {
        mid: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        pdfUrl: {
            type: String,
            default: ''
        },
        zipUrl: {
            type: String,
            default: ''
        },
        sibUrl: {
            type: String,
            default: ''
        },
        mp3Url: {
            type: String,
            default: ''
        },
        createdAt: {
            type: String,
            default: ''
        },
        report: {
            type: Function,
            default: () => {}
        },
        playSound: {
            type: Function,
            default: () => {}
        },
        pauseSound: {
            type: Function,
            default: () => {}
        }
    },
    data () {
        return {
            status: '',
            statusVariant: ''
        };
    },
    beforeMount () {
        this.setStatus();
    },
    methods: {
        toDays(ms) {
            return Math.floor(ms / (1000 * 60 * 60 * 24));
        },
        setStatus() {
            const current_date = new Date();
            const created_days_ago = this.toDays((current_date - new Date(this.createdAt)));

            if (created_days_ago < 31) {
                this.status = '*новый';
                this.statusVariant = 'text-danger';
            }
        }
    }
};

</script>

<style scoped>
    .mrow {
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .m-card {
        padding-bottom: 10px;
    }
    .download-btn {
        padding-right: 20px;
    }
    .mobile-download-btn {
        padding: 10px;
    }
</style>
